import * as React from 'react';
import styled from 'styled-components';

import {
  HeroContentQuoraPixel,
  Text,
  Box,
  AppFeatureCard,
  Image,
  InlineBox,
  PriceSection,
  Seo,
} from '../../../components';
import MainLayout from '../../../layouts/MainLayout';
import OneClickLogo from '../../../assets/images/oneclick.inline.svg';
import SnapLogo from '../../../assets/images/snap.inline.svg';
import PencilRularLogo from '../../../assets/images/pencil-rular.inline.svg';
import ArrowIcon from '../../../assets/images/arrow.inline.svg';

const HTUItem = styled(Text)`
  padding-bottom: 20px;

  &::last-child {
    padding-bottom: 0;
  }

  span.htu-item-text {
    position: relative;
    padding-left: 8px;
    top: -2px;
  }
`;

const QuoraPixel = ({ data, location }) => {
  return (
    <MainLayout heroContent={<HeroContentQuoraPixel />}>
      <Seo title="SAPP Quora Pixel" />
      <Box bg="light1" px={[0, 100]} py={50} id="features">
        <Box display="flex" flexWrap="wrap" mx={[0, -3]}>
          <Box width={[1, 1, 1, 1 / 3]} p={[4, 3]}>
            <AppFeatureCard title="One Click Install" AppLogo={OneClickLogo} />
          </Box>
          <Box width={[1, 1, 1, 1 / 3]} p={[4, 3]}>
            <AppFeatureCard title="Easy to Use" AppLogo={SnapLogo} />
          </Box>
          <Box width={[1, 1, 1, 1 / 3]} p={[4, 3]}>
            <AppFeatureCard title="Tailored Events" AppLogo={PencilRularLogo} />
          </Box>
        </Box>
      </Box>
      <Box px={[4, 100]} py={50} textAlign="center">
        <Text variant="h2">About SAPP Quora Pixel</Text>
        <Text px={[0, 160]} pt={3}>
          Simple, hassle-free, easy to use Quora Pixel app that allows you to
          track customer's each and every activity. A comprehensive tool that
          you can use to boost your marketing efficiency. Now, be more specific
          and personalize in all your marketing effort to find your customer and
          meet their demand. Enjoy this useful one-click installable tool that
          comes with the free support of our team.
        </Text>
      </Box>
      <Box bg="accent2" px={[0, 100]} pt={50}>
        <Box display="flex" flexWrap="wrap" mx={[0, -3]}>
          <Box
            width={[1, 1 / 2]}
            display="flex"
            justifyContent="center"
            flexDirection="column"
            alignItems="flex-end"
            p={[4, 0]}
          >
            <Image
              src="/assets/images/how-to-use.png"
              alt="how-to-use"
              width={['100%', 350]}
            />
          </Box>
          <Box width={[1, 1 / 2]} p={[4, 3]}>
            <Text variant="h2" mb={3}>
              How to Use
            </Text>
            <HTUItem variant="h5" fontWeight="500">
              <ArrowIcon></ArrowIcon>{' '}
              <span className="htu-item-text">
                Log in to your quora business account
              </span>
            </HTUItem>
            <HTUItem variant="h5" fontWeight="500">
              <ArrowIcon></ArrowIcon>{' '}
              <span className="htu-item-text">
                Go to <strong>Pixels & events</strong> tab
              </span>
            </HTUItem>
            <HTUItem variant="h5" fontWeight="500">
              <ArrowIcon></ArrowIcon>{' '}
              <span className="htu-item-text">
                Click on Setup Pixel and choose to{' '}
                <strong>Install manually</strong>
              </span>
            </HTUItem>
            <HTUItem variant="h5" fontWeight="500">
              <ArrowIcon></ArrowIcon>{' '}
              <span className="htu-item-text">
                Copy the pixel code and paste it into the Pixel script box
              </span>
            </HTUItem>
            <HTUItem variant="h5" fontWeight="500">
              <ArrowIcon></ArrowIcon>{' '}
              <span className="htu-item-text">
                Checkmark the events you want to track
              </span>
            </HTUItem>
          </Box>
        </Box>
      </Box>
      <Box bg="light1" px={[0, 100]} py={50}>
        <Box display="flex" flexWrap="wrap" mx={[0, -3]}>
          <Box width={[1, 1 / 2]} p={[4, 3]}>
            <Box textAlign="right" display="block" pr={4}>
              <InlineBox display="inline-block" textAlign="left">
                <Text variant="h2" mb={3}>
                  Features
                </Text>
                <HTUItem variant="h5" fontWeight="500">
                  <ArrowIcon></ArrowIcon>{' '}
                  <span className="htu-item-text">One-click install</span>
                </HTUItem>
                <HTUItem variant="h5" fontWeight="500">
                  <ArrowIcon></ArrowIcon>{' '}
                  <span className="htu-item-text">Easy to use</span>
                </HTUItem>
                <HTUItem variant="h5" fontWeight="500">
                  <ArrowIcon></ArrowIcon>{' '}
                  <span className="htu-item-text">Tailored Events</span>
                </HTUItem>
                <HTUItem variant="h5" fontWeight="500">
                  <ArrowIcon></ArrowIcon>{' '}
                  <span className="htu-item-text">
                    No technical knowledge is required
                  </span>
                </HTUItem>
                <HTUItem variant="h5" fontWeight="500">
                  <ArrowIcon></ArrowIcon>{' '}
                  <span className="htu-item-text">Free support</span>
                </HTUItem>
                <HTUItem variant="h5" fontWeight="500">
                  <ArrowIcon></ArrowIcon>{' '}
                  <span className="htu-item-text">
                    Enable or disable pixel without uninstalling the app
                  </span>
                </HTUItem>
              </InlineBox>
            </Box>
          </Box>
          <Box
            width={[1, 1 / 2]}
            display="flex"
            justifyContent="center"
            flexDirection="column"
            p={[4, 0]}
          >
            <Image
              src="/assets/images/features.png"
              alt="how-to-use"
              width={['100%', 350]}
            />
          </Box>
        </Box>
      </Box>
      <Box bg="primary">
        <PriceSection
          price={10}
          appUrl="https://apps.shopify.com/sapp-quora-pixel"
        />
      </Box>
    </MainLayout>
  );
};

export default QuoraPixel;
